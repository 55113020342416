<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <ValidationProvider name="Submition Deadline" vid="deadline" rules="required" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="4"
                label-for="date"
              >
                <template v-slot:label>
                  {{ $t('tradeFairInfoManagement.submission_deadline') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="deadline"
                  class="form-control"
                  v-model="formData.deadline"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :placeholder="$t('globalTrans.select')"
                  :config="{ static: true }"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <ValidationProvider name="Extended Deadline" vid="extended_deadline" rules="required" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="4"
                label-for="date"
              >
                <template v-slot:label>
                  {{ $t('tradeFairPartiCircularManagement.extended_deadline') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="extended_deadline"
                  class="form-control"
                  v-model="formData.extended_deadline"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :placeholder="$t('globalTrans.select')"
                  :config="{ static: true }"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
          <ValidationProvider name="Comments" vid="comments" rules="max:255">
            <b-form-group
              label-for="comments"
              slot-scope="{ errors }"
            >
              <template v-slot:label>
                {{$t('globalTrans.comments')}}
              </template>
              <b-form-textarea
                  id="comments"
                  v-model="formData.comments"
                  :state="errors[0] ? false : (valid ? true : null)"
              >
              </b-form-textarea>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" sm="12">
              <div>
                <b-table-simple bordered hover small>
                    <b-thead><b>{{ $t('tradeFairPartiCircularManagement.deadline_history') }}</b></b-thead>
                    <b-tbody>
                        <tr>
                          <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th class="text-center">{{ $t('tradeFairPartiCircularManagement.deadline') }}</b-th>
                          <b-th class="text-center">{{ $t('tradeFairPartiCircularManagement.extended_deadline') }}</b-th>
                          <b-th class="text-center">{{ $t('globalTrans.comments') }}</b-th>
                        </tr>
                        <tr v-for="(detail, inx) in deadlineHistory" :key="inx">
                          <b-td class="text-center" style="width: 10%">{{ $n(inx + 1) }}</b-td>
                          <b-td class="text-center" style="width: 10%">{{ detail.deadline | dateFormat }}</b-td>
                          <b-td class="text-center" style="width: 15%">{{ detail.extended_deadline | dateFormat }}</b-td>
                          <b-td class="text-center" style="width: 30%">{{ detail.comments }}</b-td>
                        </tr>
                    </b-tbody>
                </b-table-simple>
              </div>
            </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form1')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { fairPartiCircularDeadlineExtendStore, preDeadlineHistory } from '../../api/routes'
export default {
  name: 'Form1',
  props: ['id'],
  data () {
    return {
      internationalTradeFairServiceBaseUrl: internationalTradeFairServiceBaseUrl,
      valid: null,
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        deadline: '',
        extended_deadline: '',
        comments: ''
      },
      deadlineHistory: {}
    }
  },
  created () {
    if (this.id) {
      this.getDeadlineHistory()
      this.formData.part_circular_id = this.id
      const tmp = this.getFairPartiCircular()
      this.formData.deadline = tmp.deadline
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
  },
  mounted () {
    this.data = Object.assign({}, this.data, { fiscal_year_id: this.$store.state.currentFiscalYearId })
  },
  methods: {
    async saveUpdate () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(internationalTradeFairServiceBaseUrl, fairPartiCircularDeadlineExtendStore, this.formData)
      this.loading = false
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.loading = false
        this.$bvModal.hide('modal-form1')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
    },
    async getDeadlineHistory () {
      const params = {
          part_circular_id: this.id
        }
        this.loading = true
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, preDeadlineHistory, params)
        this.loading = false
        if (result.success) {
            this.deadlineHistory = result.data
        } else {
          this.deadlineHistory = {}
        }
    },
    getFairPartiCircular () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
  .blink_me {
    animation: blinker 2.5s linear infinite;
    color: red;
  }
  @keyframes blinker {
    50% {
      opacity: .09;
      /* background-color: green; */
    }
  }
</style>
